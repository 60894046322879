import React from 'react';
import AppMenuitem from './AppMenuitem';
import { MenuProvider } from './context/menucontext';
import { useAppAccess } from '../utils/security/useAppAccess';
import { useStore } from 'react-redux';
import { store } from '../model/redux/store';
import { UsuarioSelectors } from '../model/redux/reducers/UsuarioReducer';

const AppMenu = () => {
    const { getState } = useStore(store);
    const detalleAcceso = UsuarioSelectors.detallesAcceso(getState());

    const { acceso } = useAppAccess(detalleAcceso)

    const model = [
        {
            label: 'Inicio',
            items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }]
        },
        {
            label: 'Procesos',
            to: '/process',
            items: [
                {

                    visible: acceso.inscripcion,
                    label: 'Inscripción',
                    icon: 'pi pi-fw pi-book',
                    to: '/process/inscripcion'

                },
                {

                    visible: acceso.cobroFactura,
                    label: 'Cobro Cuota',
                    icon: 'pi pi-fw pi-wallet',
                    to: '/process/factura'

                },
                {
                    visible: acceso.realizarCobroFactura || acceso.generarNuevaFactura,
                    label: 'Facturación',
                    icon: 'pi pi-fw pi-money-bill',
                    items: [
                        {
                            visible: acceso.realizarCobroFactura,
                            label: 'Realizar Cobro',
                            icon: 'pi pi-fw pi-credit-card',
                            to: '/process/factura/realizarCobro'
                        },
                        {
                            visible: acceso.generarNuevaFactura,
                            label: 'Nueva Factura',
                            icon: 'pi pi-fw pi-file',
                            to: '/process/factura/generarFactura'
                        },
                        {
                            visible: acceso.generarCuota,
                            label: 'Generar Cuotas',
                            icon: 'pi pi-fw pi-file-edit',
                            to: '/process/factura/generarCuota'
                        },
                        {
                            visible: acceso.anularFacturaEstudiante,
                            label: 'Anular Factura',
                            icon: 'pi pi-fw pi-undo',
                            to: '/process/factura/anularfacturaestudiante'
                        }
                    ]

                },
            ]
        },
        {
            label: 'Mantenimientos',
            icon: 'pi pi-fw pi-briefcase',
            to: '/pages',
            items: [

                {
                    visible: (acceso.compania || acceso.sucursalCompania),
                    label: 'Mi Academia',
                    icon: 'pi pi-fw pi-map',
                    items: [
                        {
                            visible: acceso.compania,
                            label: 'Compania',
                            icon: 'pi pi-fw pi-map',
                            to: '/compania'
                        },
                        {
                            visible: acceso.sucursalCompania,
                            label: 'Sucursales',
                            icon: 'pi pi-fw pi-building',
                            to: '/compania/sucursal'
                        }
                    ]
                },

                {
                    visible: acceso.estudianteCreacion || acceso.transferenciaGrupo,
                    label: 'Estudiante',
                    icon: 'pi pi-fw pi-user',
                    items: [
                        {
                            visible: acceso.estudianteCreacion,
                            label: 'Estudiante',
                            icon: 'pi pi-fw pi-user',
                            to: '/estudiante'
                        },
                        {
                            visible: acceso.transferenciaGrupo,
                            label: 'Transferencia Grupo',
                            icon: 'pi pi-fw pi-reply',
                            to: '/estudiante/transferenciagrupo'
                        },
                        {
                            visible: acceso.moreInfoStudent,
                            label: 'Más Información',
                            icon: 'pi pi-fw pi-reply',
                            to: '/estudiante/studentDetail'
                        },
                        {
                            visible: acceso.cancelarInscripcion,
                            label: 'Cancelar Inscripción',
                            icon: 'pi pi-fw pi-replay',
                            to: '/estudiante/cancelarInscripcion'
                        }
                    ]
                },
                {
                    visible: acceso.suplidor || acceso.nuevaOrdenCompra || acceso.articulo || acceso.existenciaArticulo,
                    label: 'Uniformes',
                    icon: 'pi pi-fw pi-briefcase',
                    items: [
                        {
                            visible: acceso.suplidor || acceso.nuevaOrdenCompra,
                            label: 'Compras',
                            icon: 'pi pi-fw pi-shopping-cart',
                            items: [
                                {
                                    visible: acceso.nuevaOrdenCompra,
                                    label: 'Nueva Compra',
                                    icon: 'pi pi-fw pi-shopping-cart',
                                    to: '/process/compra'
                                },
                                {
                                    visible: acceso.suplidor,
                                    label: 'Suplidor',
                                    icon: 'pi pi-fw pi-truck',
                                    to: '/suplidor'
                                }
                            ]
                        },

                        {
                            visible: acceso.articulo || acceso.existenciaArticulo,
                            label: 'Inventario',
                            icon: 'pi pi-fw pi-box',
                            items: [
                                {
                                    visible: acceso.articulo,
                                    label: 'Articulo',
                                    icon: 'pi pi-fw pi-tag',
                                    to: '/inventario/articulo'
                                },
                                {
                                    visible: acceso.categoriaArticulo,
                                    label: 'Categoría Articulo',
                                    icon: 'pi pi-fw pi-tag',
                                    to: '/inventario/categoria'
                                },
                                {
                                    visible: acceso.transferenciaArticulo,
                                    label: 'Transferencia Artículo',
                                    icon: 'pi pi-fw pi-arrow-right-arrow-left',
                                    to: '/inventario/transferenciaArticulo'
                                }
                            ]
                        }
                    ]
                },
                {
                    visible: acceso.becas || acceso.otorgarBecas,
                    label: 'Becas',
                    icon: 'pi pi-inbox',
                    items: [
                        {
                            visible: acceso.becas,
                            label: 'Crear Beca',
                            icon: 'pi pi-inbox',
                            to: '/beca'
                        },
                        {
                            visible: acceso.otorgarBecas,
                            label: 'Otorgar Beca Estudiante',
                            icon: 'pi pi-ticket',
                            to: '/beca/otorgarbeca'
                        }
                    ]

                },
                {
                    visible: acceso.evento,
                    label: 'Eventos',
                    icon: 'pi pi-fw pi-calendar',
                    items: [
                        {
                            visible: true,
                            label: 'Evento',
                            icon: 'pi pi-fw pi-bookmark',
                            to: '/evento'
                        }
                    ]
                },

                {
                    visible: acceso.servicio,
                    label: 'Servicios',
                    icon: 'pi pi-fw pi-external-link',
                    items: [
                        {
                            visible: true,
                            label: 'Servicio',
                            icon: 'pi pi-fw pi-tag',
                            to: '/servicio'
                        }
                    ]
                },

                {
                    visible: (acceso.creacionUsuario || acceso.profesorCreacion || acceso.otorgaraccesoUsuario || acceso.cambiarContrasenaUsuario || acceso.cambiarContrasenaUsuarioOtro),
                    label: 'Usuario',
                    icon: 'pi pi-fw pi-user',
                    items: [
                        {
                            visible: acceso.creacionUsuario,
                            label: 'Crear / Editar Usuario',
                            icon: 'pi pi-fw pi-user',
                            to: '/usuario'
                        },
                        {


                            visible: acceso.profesorCreacion,
                            label: 'Crear / Editar Instructor',
                            icon: 'pi pi-fw pi-user',
                            to: '/profesor'
                        }

                        ,


                        {
                            visible: (acceso.otorgaraccesoUsuario || acceso.usuarioAsignarSucursal),
                            label: 'Accesos',
                            icon: 'pi pi-fw pi-shield',
                            items: [
                                {
                                    visible: acceso.otorgaraccesoUsuario,
                                    label: 'Conceder Acceso',
                                    icon: 'pi pi-fw pi-shield',
                                    to: '/usuario/otorgaracceso'
                                },
                                {
                                    visible: acceso.usuarioAsignarSucursal,
                                    label: 'Usuario Sucursal',
                                    icon: 'pi pi-fw pi-building',
                                    to: '/usuario/sucursalusuario'
                                },
                            ]
                        },

                        {
                            visible: (acceso.cambiarContrasenaUsuario || acceso.cambiarContrasenaUsuarioOtro),
                            label: 'Seguridad',
                            icon: 'pi pi-fw pi-lock',
                            items: [
                                {
                                    visible: acceso.cambiarContrasenaUsuario,
                                    label: 'Cambiar Contraseña',
                                    icon: 'pi pi-fw pi-lock',
                                    to: '/usuario/cambiarcontrasena'
                                },
                                {
                                    visible: acceso.cambiarContrasenaUsuarioOtro,
                                    label: 'Cambiar Contraseña Otro',
                                    icon: 'pi pi-fw pi-lock',
                                    to: '/usuario/cambiarcontrasenaotro'
                                }
                            ]
                        },




                    ]
                },

                {
                    visible: (acceso.mensajeatraso || acceso.grupoCreacion || acceso.detalleHorarioGrupo),
                    label: 'Configuración académica',
                    icon: 'pi pi-fw pi-cog',
                    items: [
                        {
                            visible: acceso.periodoCreacion,
                            label: 'Periodo',
                            icon: 'pi pi-fw pi-clone',
                            to: '/periodo'
                        },
                        {
                            visible: (acceso.grupoCreacion || acceso.detalleHorarioGrupo || acceso.periodoCreacion || acceso.subperiodoCreacion),
                            label: 'Grupo',
                            icon: 'pi pi-fw pi-users',
                            items: [
                                {
                                    visible: acceso.grupoCreacion,
                                    label: 'Grupo',
                                    icon: 'pi pi-fw pi-users',
                                    to: '/grupo'
                                },
                                {
                                    visible: acceso.detalleHorarioGrupo,
                                    label: 'Horario Grupo',
                                    icon: 'pi pi-fw pi-calendar-plus',
                                    to: '/grupo/detallegrupo'
                                }
                            ]
                        },
                        {
                            visible: acceso.mensajeatraso,
                            label: 'Mensaje Atraso',
                            icon: 'pi pi-info-circle',
                            to: '/mensajeatraso'
                        },
                        {
                            visible: acceso.descuento,
                            label: 'Descuento',
                            icon: 'pi pi-percentage',
                            to: '/descuento'
                        }
                    ]
                },


            ]
        },
        {
            label: 'Reportes',
            items: [
                {
                    visible: (acceso.reporteFacturaVencida || acceso.historialCobroEstudiante ||
                        acceso.estudianteAtrasoFactura),
                    label: 'Factura',
                    icon: 'pi pi-fw pi-wallet',
                    items: [
                        {
                            visible: acceso.reporteFacturaVencida,
                            label: 'Facturas Vencidas',
                            icon: 'pi pi-fw pi-exclamation-triangle',
                            to: '/report/factura/vencida'
                        },
                        {
                            visible: acceso.estudianteAtrasoFactura,
                            label: 'Estudiantes En Atraso',
                            icon: 'pi pi-fw pi-users',
                            to: '/report/factura/estudianteAtraso'
                        },
                        {
                            visible: acceso.historialCobroEstudiante,
                            label: 'Hist. Cobro Estudiante',
                            icon: 'pi pi-fw pi-history',
                            to: '/report/factura/histPagoEstudiante'
                        }
                    ]
                },
                {
                    visible: acceso.reporteInscripcionEstudianteGrupo,
                    label: 'Inscripcion',
                    icon: 'pi pi-fw pi-book',
                    items: [
                        {
                            visible: acceso.reporteInscripcionEstudianteGrupo,
                            label: 'Estudiante Grupo',
                            icon: 'pi pi-fw pi-users',
                            to: '/report/inscripcion/estudiantePorGrupo'
                        }
                    ]
                },
                {
                    visible: (acceso.reporteAsistenciaNota || acceso.reporteAsistenciaGrupo || acceso.resumenAsistenciaGrupo),
                    label: 'Asistencia',
                    icon: 'pi pi-fw pi-tablet',
                    items: [
                        {
                            visible: acceso.reporteAsistenciaNota,
                            label: 'Notas',
                            icon: 'pi pi-fw pi-file-o',
                            to: '/notaAsistencia'
                        },
                        {
                            visible: acceso.reporteAsistenciaGrupo,
                            label: 'Asistencia Grupo',
                            icon: 'pi pi-fw pi-users',
                            to: '/report/asistencia'
                        },
                        {
                            visible: acceso.resumenAsistenciaGrupo,
                            label: 'Resumen Asistencia Grupo',
                            icon: 'pi pi-fw pi-check-square',
                            to: '/report/asistencia/resumAsistenciaGrupo'
                        }
                    ]
                },
                {
                    visible: (acceso.ingresoResumenAnual || acceso.resumenCobroFecha || acceso.historialCobroFactura || acceso.estatusGrafico || acceso.ingresoDiarioCaja),
                    label: 'Ingresos',
                    icon: 'pi pi-fw pi-money-bill',
                    items: [
                        {
                            visible: acceso.ingresoResumenAnual,
                            label: 'Resumen Anual',
                            icon: 'pi pi-fw pi-chart-bar',
                            to: '/report/ingreso/ingresoResumenAnual'
                        },
                        {
                            visible: acceso.resumenCobroFecha,
                            label: 'Resumen Cobro Fecha',
                            icon: 'pi pi-fw pi-chart-pie',
                            to: '/report/ingreso/resumenCobroFecha'
                        },
                        {
                            visible: acceso.estatusGrafico,
                            label: 'Análisis de Ingresos',
                            icon: 'pi pi-fw pi-chart-line',
                            to: '/report/ingreso/estatus'
                        },
                        {
                            visible: acceso.ingresoDiarioCaja,
                            label: 'Ingreso Caja (Diario)',
                            icon: 'pi pi-fw pi-book',
                            to: '/report/ingreso/ingresoCajaDia'
                        },
                        {
                            visible: acceso.historialCobroFactura,
                            label: 'Historial Cobro Factura',
                            icon: 'pi pi-fw pi-history',
                            to: '/report/ingreso/historialCobroFactura'
                        },
                    ]
                },
                {
                    visible: acceso.existenciaArticulo,
                    label: 'Uniforme',
                    icon: 'pi pi-fw pi-briefcase',
                    items: [
                        {
                            visible: acceso.existenciaArticulo,
                            label: 'Existencia Artículo',
                            icon: 'pi pi-fw pi-calculator',
                            to: '/report/inventario/existenciaArticulo'
                        }
                    ]
                }
            ]
        },
    ];

    return (
        <MenuProvider>
            <ul className="layout-menu">
                {model.map((item, i) => {
                    return !item.seperator ? <AppMenuitem item={item} root={true} index={i} key={item.label} /> : <li className="menu-separator"></li>;
                })}


            </ul>
        </MenuProvider>
    );
};

export default AppMenu;
