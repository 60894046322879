export function setTimeOutAsync(timeout) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, timeout);
  })
}
// Formatear fecha No UTC sin hora especificada.
export function formatearFecha(fecha) {
  const partes = fecha.split('-');
  const fechaObjeto = new Date(partes[0], partes[1] - 1, partes[2]);
  const dia = fechaObjeto.getDate().toString().padStart(2, '0');
  const mes = (fechaObjeto.getMonth() + 1).toString().padStart(2, '0');
  const anio = fechaObjeto.getFullYear().toString();
  return `${dia}/${mes}/${anio}`;
}

/**
 * Metodo que permite formatear una fecha
 * @param {String} date - Objeto tipo date o String.
 * @param {String} dateFormat - dateFormat de la fecha: string , ['d/m/y' | 'y/m/d' | '' | 'iso'] para omitir la fecha y solo mostrar la hora enviar ('').
 * @param {Number} time - [12|24].
 * @param {Boolean} showSeconds - [true|false] indica si en la hora se mostraran los segundos.
 * @returns {string} Retorna la fecha formateada.
 */
export function formatDate(date, dateFormat, time, showSeconds) {

  if (date != undefined) {
    var ano = 0, mes = 1, dia = 2;
    date = !isString(date) ? date : date.replace(/\[UTC\]*/gi, '')

    var dateTime = new Date(date);

    if (dateFormat == 'iso' || dateFormat == 'ISO') { return dateTime.toISOString() }
    else {

      var fecha = [dateTime.getFullYear(), dateTime.getMonth() + 1, dateTime.getDate()]//dateTime.toISOString().split("T")[0].split("-");// dateToStringLocale(new Date(date)).toISOString().split("T")[0].split("-");

      var fechaFormateada = '';
      var timeFormateado = '';

      var separador = dateFormat ? dateFormat.replace(/[ymd]*/gi, '').charAt(0) : '/';

      var pos1 = dia, pos2 = mes, pos3 = ano;
      if (dateFormat != undefined && dateFormat != '') {
        var elementDate = dateFormat.split(separador);

        pos1 = getDatePos(elementDate[0].charAt(0))
        pos2 = getDatePos(elementDate[1].charAt(0))
        pos3 = getDatePos(elementDate[2].charAt(0))
      }

      fechaFormateada = dateFormat == '' ? '' : [paddingNumber(fecha[pos1]), paddingNumber(fecha[pos2]), paddingNumber(fecha[pos3])].join(separador);

      if (time) {
        var hora = dateTime.getHours(); hora = hora < 10 ? '0' + hora : hora; hora = time == '12' ? hora % 12 : hora; hora = (time == '12' && hora == 0) ? 12 : hora;
        var minuto = dateTime.getMinutes(); minuto = minuto < 10 ? '0' + minuto : minuto;
        var segundo = dateTime.getSeconds(); segundo = segundo < 10 ? '0' + segundo : segundo;
        var ampm = time == '12' ?
          dateTime.getHours() >= 12 ?
            ' PM' :
            ' AM' :
          '';
        timeFormateado = hora + ':' + minuto + (showSeconds == true ? ':' + segundo : '') + ampm;
        timeFormateado = fechaFormateada == '' ? timeFormateado : ' ' + timeFormateado;
      }

      return fechaFormateada + timeFormateado;
    }

  }
  return undefined
}

export function obtenerNombreMes(numeroMes) {
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  return meses[numeroMes - 1];
}

function getDatePos(dateElement) {
  var dia = 2, mes = 1, ano = 0;
  switch (dateElement) {
    case 'd': { return dia }
    case 'D': { return dia }
    case 'm': { return mes }
    case 'M': { return mes }
    case 'y': { return ano }
    case 'Y': { return ano }
    default: undefined;
  }
}

export function isString(str) {
  return typeof str === "string" ? true : false;
}

export function paddingNumber(number, fill = '0', fillCount = 1) {
  try {
    var num = parseInt(number);
    var padding = '';

    if (fillCount > 1) {
      for (let i = 0; i < fillCount; i++) {
        padding += fill;

      }
    } else {
      padding = fill;
    }

    return num >= 10 ? num : padding + num;

  } catch (error) {
    return number;
  }
}


export function formatoMonto(nStr) {

  if (nStr === '') { return '' };

  nStr = Number.parseFloat(nStr).toFixed(2)
  nStr += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

export function isEmpty(object) {

  if (object == undefined || object == null) return true;
  if (typeof object === 'string' && object.trim() === '') return true;
  if (object instanceof Array && object.length === 0) return true;
  if (object instanceof Date) return false;
  if (object instanceof Object && Object.keys(object).length === 0) return true;

  return false;
}

export function isDateExpired(date){
  const dateObj = new Date(date).getTime();
  const currentDate = new Date().getTime();
  return currentDate > dateObj;
}



export function isEmailValid(mail) {

  let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return pattern.test(mail)

}

export function isNumber(cadena) {
  // Expresión regular para números enteros o decimales
  var patron = /^-?\d*\.?\d+$/;

  // Verificar si la cadena coincide con el patrón
  return patron.test(cadena);
}

export function strConcat(...args) {
  return [...args].filter(arg => (arg != undefined && arg.trim() !== '')).join(' ');
}

function ms(t) {
  let year,
    month,
    day,
    hour,
    minute,
    second;

  second = Math.floor(t / 1000);
  minute = Math.floor(second / 60);
  second = second % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;
  month = Math.floor(day / 30);
  day = day % 30;
  year = Math.floor(month / 12);
  month = month % 12;

  return { year, month, day, hour, minute, second };
}


export function getEdad(fechaNacimiento) {

  let date = new Date(fechaNacimiento);

  let time = ms(new Date() - date);

  let edad = time.year;

  return edad;

}

export function getArray(posiciones) {
  let arr = new Array(posiciones);

  for (let i = 0; i < posiciones; i++) {
    arr[i] = i;
  }

  return arr;

}

export function toDay(dayCode) {
  if (isEmpty(dayCode)) return undefined;

  dayCode = parseInt(dayCode);

  switch (dayCode) {
    case 1: { return 'Domingo' }
    case 2: { return 'Lunes' }
    case 3: { return 'Martes' }
    case 4: { return 'Miércoles' }
    case 5: { return 'Jueves' }
    case 6: { return 'Viernes' }
    case 7: { return 'Sábado' }
    default: return undefined;
  }
}

function toMilliseconds(cantidad, unidadTiempo) {
  let milisegundos = 0;
  switch (unidadTiempo) {
    case 'segundos':
      milisegundos = cantidad * 1000;
      break;
    case 'minutos':
      milisegundos = cantidad * 1000 * 60;
      break;
    case 'horas':
      milisegundos = cantidad * 1000 * 60 * 60;
      break;
    case 'dias':
      milisegundos = cantidad * 1000 * 60 * 60 * 24;
      break;
    default:
      throw new Error('Unidad de tiempo no válida.');
  }
  return milisegundos;
}

export function sumarFecha(fecha = new Date(), cantidadSumar, unidad = "minutos" | "horas" | "dias") {

  let cantidadEnMilliseconds = toMilliseconds(cantidadSumar, unidad);
  return new Date(fecha.getTime() + cantidadEnMilliseconds);

}

export function getJsonFromKeys(json, keys = []) {
  let result = json;

  for (let i = 0; i < keys.length; i++) {
    result = result[keys[i]];
  }

  return result;

}

export function startCase(sentence) {
  return sentence.replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
}

export function formatCurrency(value) {
  if (value == undefined || value == null) {
    return;
  }
  return value.toLocaleString('en-US', { style: 'currency', currency: 'DOP' });
};


export function capitalizeFLetter(word) {
  if (word == null || word == undefined || word == '') {
    return word;
  }

  return word[0].toUpperCase() +
    word.slice(1)
}

export function randomUUID() {
  return crypto.randomUUID().replace(/-/g, '');
}

export function bytesToMb(bytes) {
  if (bytes == null || bytes == undefined || bytes == 0) {
    return 0;
  }

  return ((bytes / 1024) / 1024).toFixed(2);;
}

export function roundNum(numero) {
  if (numero == undefined || numero == null) {
    return '';
  }
  return (numero < 9 ? ' ' : '') + numero.toFixed(1)

}

export function capitalizeFirstLetter(text) {
  if (!text) return text;
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function obtenerDescripcionDetalle(tipoDetalle, detalle) {
  if (tipoDetalle == 'AR') {
    return detalle?.articulo?.descripcion;
  } else if (tipoDetalle == 'SE') {
    return detalle?.servicio?.descripcion;
  } else {
    return detalle?.evento?.descripcion;
  }
}