import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";



const ConfirmDialogFooter = ({ onCancel, onOk }) => {
    return (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={onCancel} />
            <Button label="Sí" icon="pi pi-check" className="p-button-text" onClick={onOk} />
        </>
    )
}


export function ConfirmDialog({ message, visible, onCancel, onOk, onClose }) {
    if (!visible) {
        return null;
    }

    return (
        <Dialog visible={visible} style={{ width: '450px' }} header="Confirmación" modal footer={<ConfirmDialogFooter onCancel={onCancel} onOk={onOk} />} onHide={onClose}>
            <div className="flex align-items-center justify-content-center">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                <span>
                    {message}
                </span>
            </div>
        </Dialog>
    )
}


const ConfirmDialogFooterWithAction = ({ onCancel, onOk }) => {
    return (
        <>
            <Button type="button" label="OK" onClick={onOk} icon="pi pi-check" className="p-button-secondary" />
        </>
    )
}


export function InfoDialogWithAction({ message, visible, onCancel, onOk, onClose }) {
    if (!visible) {
        return null;
    }

    return (
        <Dialog visible={visible} style={{ width: '450px' }} header="Información" modal footer={<ConfirmDialogFooterWithAction onCancel={onCancel} onOk={onOk} />} onHide={onClose}>
            <div>
                <p>
                    {message}
                </p>
            </div>
        </Dialog>
    )
}